import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  EventEmitter,
  Output,
  Input,
} from "@angular/core";
import { LocalDataSource, ViewCell } from "ng2-smart-table";
import swal from "sweetalert2";
import { CreateMail } from "../../../models/oapinfo/createmail";
import { UsersField } from "../../../models/usersInfo/users-fields";
import { Course } from "../../../models/Coursesinfo/course";
import { CoursesService } from "../../../models/Coursesinfo/courses.service";
import { School } from "../../../models/Coursesinfo/school";
import { TypeCourse } from "../../../models/Coursesinfo/typecourse";
import { OapinfoService } from "../../../models/oapinfo/oapinfo.service";
import { UsersService } from "../../../models/usersInfo/users.service";
import { AuthService } from "../../../models/Auth/auth.service";
import { NavigationExtras, Router } from "@angular/router";
import { ProfileNavComponent } from "../../../components/profile-nav/profile-nav.component";
import { AngularCsv } from "angular7-csv";
import { ExcelService } from "app/models/attachment/excel.service";
import { ClassificationUser } from "app/models/usersInfo/classification-user";
import { environment } from "environments/environment";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
  selector: "button-view-fechas-admin-user",
  template: ` {{ renderValue | colombiapipe : "YYYY/MM/DD" }} `,
})
export class ButtonViewFechasAdminUser
  implements ViewCell, OnInit, AfterViewChecked
{
  constructor() {
    /** */
  }
  renderValue: string;
  @Input() value: string;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value;
  }
  ngAfterViewChecked() {
    this.renderValue = this.value;
  }
  onClick() {
    this.save.emit(this.rowData);
  }
}

@Component({
  selector: "link-renderer",
  template: `<a
    [routerLink]="['/Cv-Public', rowData.CedocEmail]"
    target="_blank"
    class=""
    >Ver</a
  >`,
})
export class ResumeLinkComponent {
  constructor(private _router: Router) {}
  @Input() rowData: any;
}

// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO

@Component({
  selector: "AdminUser",
  templateUrl: "./admin-user.component.html",
  styleUrls: ["./admin-user.component.css"],
})
export class AdminUserComponent implements OnInit, OnDestroy {
  importStudents: UsersField[] = [];
  exportStudents: UsersField[] = [];
  dataControl: any[] = [];
  isUploading = false;
  isUploadingValidate = false;

  data: any[];
  schoolID: number = 0;
  courseoferID: number = 0;
  typecourseID: number = 0;
  courseofers: Course[];
  schools: School[] = [];
  typecourses: TypeCourse[];
  courseofer: Course;
  user: number;

  settingsOAP = {
    noDataMessage: "No hay datos disponibles",
    add: {
      addButtonContent: "",
      createButtonContent: "",
      cancelButtonContent: "",
      confirmCreate: true,
    },
    edit: {
      editButtonContent: "",
      saveButtonContent: "",
      cancelButtonContent: "",
      confirmSave: true,
    },
    pager: {
      perPage: 15,
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      Curriculum: {
        title: "Hoja de vida",
        filter: false,
        editable: false,
        type: "custom",
        renderComponent: ResumeLinkComponent,
        width: "100px",
      },
      Document: {
        title: "Cédula",
        filter: false,
        editable: false,
      },
      CedulaMil: {
        title: "Cédula  Militar",
        filter: false,
        editable: false,
      },
      Names: {
        title: "Nombre",
        filter: false,
        editable: false,
      },
      LastNames: {
        title: "Apellido",
        filter: false,
        editable: false,
      },
      CellPhone: {
        title: "Número Celular",
        filter: false,
        editable: false,
      },
      Email: {
        title: "Email Personal",
        filter: false,
        editable: false,
      },
      CedocEmail: {
        title: `Email ${environment.PRINCIPAL_APP_NAME}`,
        filter: false,
        editable: false,
      },
      NameCourseOfer: {
        title: "Curso Inscrito",
        filter: false,
        editable: false,
      },
      NameSchool: {
        title: "Escuela",
        filter: false,
        editable: false,
      },
      IsCreated: {
        title: "conCorreo",
        filter: false,
        editable: false,
      },
      TimeCreate: {
        title: "Fecha de Creación",
        filter: false,
        editable: false,
        type: "custom",
        renderComponent: ButtonViewFechasAdminUser,
        onComponentInitFunction(instance) {
          instance.save.subscribe((row) => {
            console.log("algo se edito");
            // this.saveDocenteDetails(row.id)
          });
        },
      },
    },
  };
  isAuth = false;
  isAdmin = false;
  isSuperAdmin = false;
  isRolPersonal = false;
  allMySchoolsIDs = [];
  sourceOAP: LocalDataSource;

  public SCHOOL_NAME: string = environment.SCHOOL_NAME;
  public showFamilyModal: boolean = false;
  public showAttendantModal: boolean = false;
  public showCreateUserModal: boolean = false;
  public relationships: any[] = [];
  @Output() closeModalEvent = new EventEmitter<any>(); //TODO: David revisar

  constructor(
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private authService: AuthService,
    private router: Router,
    private excelSrv: ExcelService,
    public profileNavComponent: ProfileNavComponent
  ) {
    this.sourceOAP = new LocalDataSource(); // create the source
    for (let role of this.profileNavComponent.roles) {
      this.allMySchoolsIDs.push(role.SchoolID);
      if (role.name == "Agrupacion" || role.name == "Horarios") {
        this.isAuth = true;
        this.schoolID = role.SchoolID;
      }
      if (role.name == "Personal") {
        this.isAuth = true;
        this.isSuperAdmin = true;
        this.isRolPersonal = true;
        this.schoolID = role.SchoolID;
      }
      if (role.name == "admin" || role.name == "Registro y Control") {
        this.isAuth = true;
        this.isAdmin = true;
        this.schoolID = role.SchoolID;
      }
      if (role.name == "Bienestar") {
        this.isAuth = true;
        this.schoolID = role.SchoolID;
      }
    }
    if (this.isSuperAdmin || this.isRolPersonal || this.isAdmin)
      this.getAllOAPdata(); // Load data if user has admin/personal rol
    else this.settingsOAP.delete.deleteButtonContent = "";
    if (!this.isAuth) this.router.navigate(["/"]);
    this.getSchoolsList();
    this.coursesService
      .getAllTypeCourse()
      .subscribe((p) => (this.typecourses = p));
    this.coursesService
      .getAllCourseBySchoolAndTypeCourse(this.schoolID, 0)
      .subscribe((p) => (this.courseofers = p));
    this.courseofer = {
      // SchoolID: this.schoolID,
      SchoolID: 0,
      CourseOferID: 0,
      TypeCourseID: 0,
    };
  }

  openCreateUserModal() {
    this.showCreateUserModal = true;
  }

  ngOnInit() {
    this.usersService.getRelationships().subscribe((resp) => {
      this.relationships = resp;
    });

    this.user = parseInt(this.authService.getCurrentUser()); //devuelve el id del usuario
    this.closeModalEvent.subscribe((valor) => {
      this.showCreateUserModal = valor;
    });
  }

  getAllOAPdata(): void {
    console.log("getAllOAPdata");
    this.oapinfoService.getAllOapV2().subscribe(
      (p) => {
        this.data = p.map((element) => {
          if (element["school"])
            element["NameSchool"] = element["school"].NameTSchool;
          return element;
        });
        this.sourceOAP.load(this.data);
      },
      (error) => {
        console.log("error getAllOAPdata", error);
      }
    );
  }

  getSchoolsList(): void {
    if (this.isRolPersonal) {
      this.coursesService.getAllSchools().subscribe((p) => (this.schools = p));
    } else {
      this.coursesService.getSchoolByID(this.schoolID).subscribe((p) => {
        this.schools.push(p);
        this.getSchoolParent(p.DepenSchoolID);
      });
      this.coursesService.getAllSchoolByDepend(this.schoolID).subscribe((p) => {
        this.schools.push(...p);
      });
    }
  }
  getSchoolParent(idParent: number): void {
    this.coursesService.getSchoolByID(idParent).subscribe((p) => {
      this.schools.push(p);
    });
    /**
    this.coursesService.getAllSchoolChildrensBySchoolByID(this.schoolID,this.allMySchoolsIDs).subscribe(p => {
      this.schools = p
      console.log('result: ',p);
    });
    */
  }

  ngOnDestroy() {}

  selectSchool(model: UsersField) {
    this.courseofer.CourseOferID = 0;

    if (this.isSuperAdmin) this.schoolID = model.SchoolID;

    this.coursesService
      .getAllCourseBySchoolAndTypeCourse(model.SchoolID, this.typecourseID)
      .subscribe((p) => (this.courseofers = p));
  }

  selectTypeCourse(model: UsersField) {
    this.typecourseID = model.TypeCourseID;
    this.coursesService
      .getAllCourseBySchoolAndTypeCourse(
        this.schoolID,
        model.TypeCourseID,
        false
      )
      .subscribe((p) => {
        this.courseofers = p;
      });
  }

  selectCourse(model: Course) {
    this.courseoferID = model.CourseOferID;
    this.oapinfoService.getAllOap(model.CourseOferID).subscribe((p) => {
      this.data = p.map((element) => {
        if (element["school"]) {
          element["NameSchool"] = element["school"].NameTSchool;
        }

        return element;
      });
      this.exportStudents = this.data;
      this.sourceOAP.load(this.data);
    });
  }

  changeSchool() {
    if (this.isSuperAdmin || this.isRolPersonal || this.isAdmin) {
      this.oapinfoService
        .changeSchool(this.user, this.courseofer.SchoolID)
        .subscribe((p) => {
          console.debug(p);
          window.location.reload();
        });
    }
  }

  mail() {
    this.oapinfoService.getFileToMail(this.courseoferID).subscribe((p) => {
      this.data = p;
      console.debug(this.data);
      this.sourceOAP.load(this.data);
    });
  }

  uploadGoogle(fileInput: any) {
    if (this.isSuperAdmin) {
      var fileToUpload = <File>fileInput.target.files[0];
      swal({
        title: "Cargar Archivo",
        text: "Esta Operación Puede tardar un poco, te avisaremos cuando termine",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, Cargar!",
      }).then((result) => {
        console.log(result);
        if (result.value) {
          //console.log("se hizo ok")

          console.debug(fileToUpload);
          this.authService.swalLoading("Cargando Infomación CSV");
          this.oapinfoService
            .sendGoogle(fileToUpload)
            .then((result) => {
              console.log(result);
              swal("Cargado!", "Se Cargó exitosamente", "success");
            })
            .catch(function (err) {
              console.error("Augh, there was an error!", err.error.message);
              swal(
                "Error al cargar el CDP",
                "No se pudo cargar el archivo CVS, detalles: " +
                  err.error.message,
                "error"
              );
              //this.authService.swalError("Error al cargar el CDP","No se pudo cargar el archivo CVS, detalles: "+err)
            });
        } else {
          console.log("se cancelo");
        }
      });
    }
  }

  generar(tableId: string) {
    this.excelSrv.exportToFile("StudentList", tableId);
  }

  onSearch(query: string = "") {
    if (query == "") {
      this.sourceOAP.setFilter([]);
    } else {
      query = query.replace(/\s/g, "");

      this.sourceOAP.setFilter(
        [
          {
            field: "CedocEmail",
            search: query,
          },
          {
            field: "CedulaMil",
            search: query,
          },
          {
            field: "Document",
            search: query,
          },
        ],
        false
      );
      if (this.sourceOAP.count() == 0) this.findUserInDB(query);
    }
  }

  findUserInDB(data: string): void {
    if (Number.isNaN(Number(data))) return;
    if (Number(data) <= 0) return;
    this.oapinfoService.getAllOapV2ByUserDoc(data).subscribe(
      (p) => {
        console.log("findUserInDB data loaded: ", p);
        this.data = p.map((element) => {
          if (element["school"])
            element["NameSchool"] = element["school"].NameTSchool;
          return element;
        });
        this.sourceOAP.load(this.data);
      },
      (error) => {
        console.log("error findUserInDB", error);
      }
    );
  }

  onDeleteConfirm(event): any {
    if (!(this.isAdmin || this.isSuperAdmin)) {
      swal("No se puede eliminar", "No tiene Permisos", "error");
    } else if (event.data.IsAcepted == true) {
      swal("No se puede eliminar", "Usuario ya fue aceptado", "error");
    } else {
      swal({
        title: "¿Está seguro?",
        text: "Será eliminado el usuario",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, eliminar",
      }).then((result) => {
        if (result.value) {
          this.usersService.DeleteUserByID(event.data.id).subscribe(
            (p) => {
              swal(
                "Eliminado",
                "Se elimino Correctamente el usuario.",
                "success"
              );
              event.confirm.resolve();
              //this.ngOnInit();
            },
            (e) => {
              swal("Error", "No se puede eliminar usuario", "error");
            }
          );
        }
      });
    }
  }

  isValidValueFromExcel(value): boolean {
    return !(
      value == undefined ||
      value == null ||
      value == "null" ||
      value == "NUL" ||
      value == " "
    );
  }

  public onFileChange(evt: any) {
    const selectedFile = evt.target.files[0];
    if (!selectedFile) return;

    this.excelSrv.readExcel(selectedFile).then((data) => {
      const students = data.map((e) => {
        const basicData = {
          Name1: e["Nombre 1"],
          Name2: e["Nombre 2"],
          LastName1: e["Apellido 1"],
          LastName2: e["Apellido 2"],
          Document: e["Documento"],
          CellPhone: e["Celular"],
          Email: e["Email"],
        };

        const clsUser = {
          eps: e["EPS"],
          ers: e["Caja Compensación"],
          age: e["Edad"],
          graduationDate: e["Año Graduación"],
          schoolingLevel: e["Nivel Escolaridad Actual"],
          currentOccupation: e["Ocupación laboral Actual"],
          companyName: e["Nombre de Empresa"],
          companyCity: e["Ciudad de la Empresa"],
          businessSector: e["Sector de la Empresa"],
          businessEmail: e["Correo de la empresa"],
          businessPhoneNumber: e["Telefono de la empresa"],
          chargeCompany: e["Cargo que desempeña"],
          salary: e["Salario"],
          lastStudy: e["Ultimo estudio realizado"],
          specialRecognition: e["¿Reconocimiento en su profesion?"],
          socialImpactProject: e["¿Proyecto de impacto social?"],
          externalPresentations: e["¿Ponencias en el exterior?"],
          researchGroup: e["¿Pertenece a algun grupo de investigación?"],
        };

        return { ...basicData, clsUser };
      });

      this.importStudents = students;
      this.sourceOAP.load(
        this.importStudents.map((student) => {
          student.Names = `${student.Name1} ${student.Name2}`;
          student.LastNames = `${student.LastName1} ${student.LastName2}`;
          student.CedulaMil = student.Document;
          return student;
        })
      );

      this.isUploadingValidate = true;
    });
  }

  exportData(tableId: string) {
    this.excelSrv.exportToFile("StudentList", tableId);
  }

  createList() {
    this.dataSendedCount = 0;
    this.dataSendedWithErrorCount = 0;
    this.dataControl = [];
    swal({
      title: "¿Está seguro?",
      text: "Se crearan " + this.importStudents.length + " estudiantes",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, crear",
    }).then((result) => {
      if (result.value) {
        this.createStudents();
      }
    });
  }

  /**
   * Method to create students by registering them and handling user courses.
   */
  private createStudents() {
    if (this.courseofer.CourseOferID === 0 || this.courseofer.SchoolID === 0)
      return;

    swal({
      title: "Cargando...",
      text: "Un momento por favor.",
      type: "info",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });

    const obs: Observable<any>[] = this.importStudents.map((student) =>
      this.registerStudent(student)
    );
    forkJoin(obs).subscribe({
      complete: () => {
        this.dataSendedCount += 1;
        swal("Hecho", "Usuarios registrados correctamente.", "success");
      },
      error: (err) => {
        console.log(err);
        swal(
          "Error",
          `${err.error.message || "Error interno del servidor."}`,
          "error"
        );
      },
    });
  }

  /**
   * Method to register a student by sending user data to the registration service.
   * @param student
   * @returns An observable
   */
  private registerStudent(student: any): Observable<any> {
    const userData = this.buildUserData(student);
    return this.usersService.registerUser(userData).pipe(
      switchMap((response: any) => {
        const { status } = response;

        // If the user is already registered, omit the next part
        if (status === "OK") return of(response);

        return this.handleNewUser(response.data);
      })
    );
  }

  /**
   * Method to handle a new user by checking and assigning a user course if necessary.
   * @param userData
   * @returns An observable
   */
  private handleNewUser(userData: any): Observable<any> {
    return this.usersService
      .findUserCourse(userData.UserID, this.courseofer.CourseOferID)
      .pipe(
        switchMap((userCourse: any[]) => {
          // If the user already has a course, do nothing
          if (userCourse.length > 0) return of(null);

          const person = {
            UserID: userData.UserID,
            CourseOferID: this.courseofer.CourseOferID,
            SchoolID: this.courseofer.SchoolID,
          };

          return this.usersService.assignCourseOfer(person);
        })
      );
  }

  /**
   * Method to build user data based on student information.
   * @param student
   * @returns User data object
   */
  private buildUserData(student: any): any {
    return {
      schoolName: this.SCHOOL_NAME,
      accountType: "student",
      isMilitary: false,
      isTest: !environment.production,
      basicInfo: {
        typeUserId: 5,
        firstName: student.Name1,
        middleName: student.Name2,
        lastName: student.LastName1,
        secondLastName: student.LastName2,
        email: student.Email,
      },
      documentInfo: {
        documentTypeId: 1,
        document: student.Document.toString(),
      },
      contactInfo: {
        cellphone: student.CellPhone.toString(),
        email: student.Email,
      },
      courseInfo: {
        courseOfferId: this.courseofer.CourseOferID,
        schoolId: this.courseofer.SchoolID,
      },
    };
  }

  public checkSchoolAndCourse() {
    if (this.courseofer.CourseOferID === 0 || this.courseofer.SchoolID === 0) {
      swal(
        "",
        `Por favor, selecciona la escuela y el curso al que pertenecen los estudiantes que estás a punto de cargar.`,
        "warning"
      );
      return;
    }

    this.isUploading = true;
  }

  dataSendedCount = 0;
  dataSendedWithErrorCount = 0;
  showSuccesFinalMessage() {
    if (this.dataSendedCount != this.importStudents.length) return;
    swal({
      title: "Carga Finalizada",
      text: `La carga de datos ha finalizado con ${
        this.dataSendedCount - this.dataSendedWithErrorCount
      } registros exitosos`,
      type: "info",
      showCancelButton: false,
      confirmButtonClass: "btn",
      confirmButtonText: "Continuar",
    }).then((result) => {
      if (this.dataSendedWithErrorCount > 0) this.showErrorFinalMessage(0);
    });
  }
  showErrorFinalMessage(errorCount: number) {
    if (errorCount >= this.dataSendedWithErrorCount) return;
    swal({
      title: "Error",
      text: `${this.dataControl[errorCount].error}`,
      type: "error",
      confirmButtonClass: "btn",
      confirmButtonText: "Continuar",
    }).then((result) => {
      this.showErrorFinalMessage(errorCount + 1);
    });
  }
}
