import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource, ViewCell } from "ng2-smart-table";
import { CoursesService } from "../../../models/Coursesinfo/courses.service";
import { AuthService } from "../../../models/Auth/auth.service";
import { ProfileNavComponent } from "../../../components/profile-nav/profile-nav.component";
import { Course } from "../../../models/Coursesinfo/course";
import { School } from "../../../models/Coursesinfo/school";
import { AttachmentService } from "../../../models/attachment/attachment.service";
import { isNullOrUndefined, log } from "util";
import { AngularCsv } from 'angular7-csv';
import { OwlOptions } from 'ngx-owl-carousel-o';

import * as moment from 'moment';
import { catchError, map, switchMap, take } from "rxjs/operators";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable, forkJoin, from, of, range, throwError } from "rxjs";
import { environment } from "environments/environment";


declare let jQuery: any;


// INICIO COMPONENTE PARA EL NG SMART TABLE DE ACORDE
@Component({
  selector: 'is-according-professor-table',
  template: `
  <span *ngIf="renderValue=='Si'" >  {{renderValue}}</span>
  <span *ngIf="renderValue=='No'"   style='color: red;'>  {{renderValue}}</span>
      `
})
export class AccordingViewProfessor implements ViewCell, OnInit, AfterViewChecked {
  constructor(

  ) { }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()


  ngOnInit() {
    // console.log("aca esta el render despues")
    //  console.log(this.intermediate.Grups)
    if (!isNullOrUndefined(this.value)) {
      if (this.value.toString() == "true" || this.value.toString() == "1")
        this.renderValue = "Si"
      if (this.value.toString() == "false" || this.value.toString() == "0")
        this.renderValue = "No"
    }
  }
  ngAfterViewChecked() {
    this.renderValue = String(this.value);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS

@Component({
  selector: "StudentFinal",
  templateUrl: "./student-final.component.html"
})
export class StudentFinalComponent {

  public UrlAPIBackV2 = environment.UrlAPIBackV2
  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  date1_html: any;
  date2_html: any;
  date3_html: any;
  date4_html: any;
  date5_html: any;
  date6_html: any;
  serverTime: Date;
  public mask = [
    /[1-2]/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /[0-1]/,
    /\d/,
    "-",
    /[0-3]/,
    /\d/
  ];

  enablesavefile = false
  totalStudent: number;
  date1: any;
  date2: any;
  date3: any;
  date4: any;
  date5: any;
  date6: any;

  percentages: any = {
    Note1: 0,
    Note2: 0,
    Note3: 0,
    Note4: 0,
    Note5: 0,
    Note6: 0,
  }

  user: string;
  ErrorGroup: string;
  IsPersonal = false;
  showdata: boolean = false;
  SubjectsProfesor: any;
  Subjects: any = [];
  SubjectGID: number = 0;
  coursesbyschool: Course[] = [];
  schools: School[] = [];
  SchoolID: number = 25;
  counterNota_info = []
  counterNota1: number = 0;
  counterNota2: number = 0;
  counterNota3: number = 0;
  midTermSelected: string = "";
  enablesave: boolean = false;
  enableclose: boolean = true;
  enablesaveprocess: boolean = false;
  inspec: boolean = false
  today = new Date();
  ModelSelected: any;
  SubjectSelected: any;
  submitbuton = "Crear";
  Groupss: any = { id: 0 };
  Groups: any;
  DatosARegistrar: number;
  ContadorWhile: number;
  ContadorWhileAnterior: number;
  courses: Course;
  isActive: boolean = true;

  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;

  isDataReady = false;
  isSchoolReady = false;

  extendDates = {
    SubjectGroupID: 0,
    MidTermID: 0,
    ProfileWhAutorizeID: 0,
    IsExtension: false,
    DateStartRecord: new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "/"),
    DateEndRecord: new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "/"),
    Excuse: "",
    attachmentLink: ""
  };
  listStudents: any;
  numberMidTerms: number = 0;


  dateInfos = []
  dateInfos_html = []


  /** VARIABLES TABLA DE NOTAS */

  sourceStudentRecord: LocalDataSource;

  /** VARIABLES TABLAS HISTORIAL DE NOTAS  */
  public showdataHistory: boolean = false;

  private _hiddenColumns = [];
  public SHCOOL_NAME = environment.SCHOOL_NAME;

  public settingsStudentRecordHistory: any

  sourceStudentRecordHistory: LocalDataSource;
  originalStudents;

  public usersToPrint: any[] = [];
  public dateRecordToEdit: any = {};
  public SCHOOL_LOGO_DYNAMIC_SVG: any = (id: number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
  public CEMIL_ICON: any = environment.CEMIL_ICON;
  public EJC_ICON: any = environment.EJC_ICON;
  public URL_BACK_V1: any = environment.UrlAPI;
  public qualitativeDescription: any[] = [];

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
    public attachmentService: AttachmentService
  ) {
    //GUARDO LA HORA DEL SERVIDOR
    this.authService
      .getTime()
      .then(server2 => (this.serverTime = server2.time));

    this.coursesService.getAllSchools().pipe(take(1)).subscribe(p => (this.schools = p));
    this.sourceStudentRecord = new LocalDataSource(); // create the source
    this.sourceStudentRecordHistory = new LocalDataSource(); // create the source

    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(["/register"]);
    } else {
      //DE LO CONTRARIO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        let validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            this.inspec = true
          }
          if (role.name == "admin") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Registro y Control") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }

          if (role.name == "Docente") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
          }

          if (role.name == "Personal") {
            this.IsPersonal = true;
          }
        }

        if (!validar_usuario.includes(true)) {
          this.router.navigate(["/"]);
        }




        this.coursesService.getAllSchoolByDepend(this.SchoolID).pipe(take(1)).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend

            this.isSchoolReady = true
            this.isDataReady = true
            this.LoadData()
          } else {
            this.isDataReady = true
            this.LoadData()

          }
        })

      } else {
        this.router.navigate(["/"]);
      }
    }
  }

  private notesCellRenderer(cell) {
    const currentValue = Number(cell);
    let logo = null;

    if (this.qualitativeDescription.length > 0) {
      for (const item of this.qualitativeDescription) {
        const [min, max] = item.range.split('-').map(Number);
        if (currentValue >= min && currentValue <= max) {
          logo = item.showImage ? environment.UrlAPIBackV2 + item.imageURL : null;
          break;
        }
      }
    }

    if (logo && this.SubjectSelected.TypeRecord == "Cualitativo")
      return `<img src="${logo}" alt="note icon" class="note-icon"/>`;

    if (cell >= 1 && cell <= 2.5) {
      return `<div class="ag-grid__pill ag-grid__pill--red">${cell}</div>`;
    } else if (cell >= 2.5 && cell <= 3.5) {
      return `<div class="ag-grid__pill ag-grid__pill--orange">${cell}</div>`;
    } else {
      return `<div class="">${cell}</div>`;
    }
  }

  setNewSchool(id) {
    this.SchoolID = id
    this.isDataReady = true
    this.LoadData()
  }

  LoadData() {
    this.showdataHistory = false;
    this.courses = null;
    this.SubjectSelected = null;
    this.SubjectGID = null;
    //LLAMO LOS CURSOS
    this.coursesService
      .getAllCoursesBySchool(this.SchoolID, this.isActive)
      .pipe(take(1)).subscribe(p => {
        this.coursesbyschool = p.sort(function (a, b) {
          // Ascending: first age less than the previous
          return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
        });
      });
  }

  filterSubjectsByGrade(text:any): number {
  
    const courseRegex = /(prejardín|jardín|transición)/i;
    const gradeRegex = /grado\s*(\d+)/i;
    const courseResult = courseRegex.exec(text);
    let courseIndex = 0;
    if (courseResult && courseResult.length > 1) {
      const courseName = courseResult[1].toLowerCase();
      console.log(courseName);
      switch (courseName) {
        case 'prejardín':
          courseIndex = 1;
          break;
        case 'jardín':
          courseIndex = 2;
          break;
        case 'transición':
          courseIndex = 3;
          break;
        default:
          break;
      }
      return courseIndex;
    }
    const gradeResult = gradeRegex.exec(text);
    if (gradeResult && gradeResult.length > 1)
      courseIndex = parseInt(gradeResult[1]);
    return courseIndex;
  
}


  callsubjects(model: any) {
    this.showdataHistory = false;
    this.showdataHistory = false;
    this.SubjectSelected = null;
    this.SubjectGID = null;
    this.Subjects = [];

  
    if (environment.SCHOOL_NAME == "CEDOC")
      this.coursesService
        .getSubjectsByCourseMaya(this.courses.CourseID)
        .pipe(take(1)).subscribe(response => {
          if (response.length > 0) {
            this.Subjects = response;
            this.qualitativeDescription = model.CourseSchoolID.course.qualitativeDescriptions || [];
          }
        });
    if (environment.SCHOOL_NAME == "CELIC") {
      let filter = {where: {and: [{CourseID: this.courses.CourseID} ,  { SemesterNumber: this.filterSubjectsByGrade(this.courses.NameCourseOfer) } ]}}
      this.coursesService
        .getSubjectsByCourseMayaCELIC(JSON.stringify(filter))
        .pipe(take(1)).subscribe(response => {
          if (response.length > 0) {
            this.Subjects = response;
            this.qualitativeDescription = model.CourseSchoolID.course.qualitativeDescriptions || [];
          }
        });
    }
  }

  callgroups(model: any) {
    this.showdataHistory = false;
    this.SubjectGID = null;
    this.SubjectsProfesor = [];
    this.coursesService
      .getSubjectGroupsBySubject(this.courses.id, this.SubjectSelected.id)
      .pipe(take(1)).subscribe(response => {
        if (response.length > 0) {
          this.SubjectsProfesor = response;
        }
      });

  }




  IsBetwen(p) {
    let startDate = moment(p[0].DateStartRecord, "YYYY-MM-DD HH:mm:ss")
      .startOf("day")
      .toString();
    let untilDate = moment(p[0].DateEndRecord, "YYYY-MM-DD HH:mm:ss")
      .endOf("day")
      .toString();

    let today = moment(this.serverTime)
      .add(5, "hours")
      .toString();
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let firstDate = new Date(startDate);
    let secondDate = new Date(untilDate);
    let todayDate = new Date(today);
    if (
      firstDate.getTime() < todayDate.getTime() &&
      todayDate.getTime() < secondDate.getTime()
    )
      return true;

    return false;
  }

  uploadFile(fileInput: any) {
    this.fileToUpload = <File>fileInput.target.files[0];
    if (this.fileToUpload.size > 5000000) {//5M
      this.imagebuton = 'Error de Archivo'
      this.isImageError = true
      this.enablesavefile = false
    }
    else {
      this.imagebuton = 'Archivo OK'
      this.isImageError = false
      this.enablesavefile = true
    }
  }

  resetTable() {
    const newTableData = {

      actions: {
        columnTitle: "Acciones"
      },

      noDataMessage: "No hay datos disponibles",

      add: {
        addButtonContent: "",
        createButtonContent: "",
        cancelButtonContent: "",
      },
      edit: {
        editButtonContent: '<i class="fa fa-pencil-square"></i>',
        saveButtonContent: '<i class="fa fa-floppy-o"></i>',
        cancelButtonContent: '<i class="fa fa-window-close"></i>',
        confirmSave: true
      },
      pager: {
        perPage: 30
      },
      delete: {
        deleteButtonContent: "",
        confirmDelete: true
      },

      columns: {
        LastNames: {
          title: "Apellido",
          filter: false,
          editable: false,
        },
        Names: {
          title: "Nombre",
          filter: false,
          editable: false
        },
        Note1: {
          title: "Nota 1",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell)
        },
        Absences1: {
          title: "Inasistencias 1",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade1: {
          title: "Recuperación 1",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording1: {
          title: 'Conforme 1',
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          }
        },
        Note2: {
          title: "Nota 2",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell),
          cellClass: 'd-none',
        },
        Absences2: {
          title: "Inasistencias 2",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade2: {
          title: "Recuperación 2",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording2: {
          title: "Conforme 2",
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          },
        },
        Note3: {
          title: "Nota 3",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell),
        },
        Absences3: {
          title: "Inasistencias 3",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade3: {
          title: "Recuperación 3",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording3: {
          title: "Conforme 3",
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          },
        },


        Note4: {
          title: "Nota 4",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell),
          cellClass: 'd-none',
        },
        Absences4: {
          title: "Inasistencias 4",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade4: {
          title: "Recuperación 4",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording4: {
          title: "Conforme 4",
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          },
        },


        Note5: {
          title: "Nota 5",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell),
          cellClass: 'd-none',
        },
        Absences5: {
          title: "Inasistencias 5",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade5: {
          title: "Recuperación 5",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording5: {
          title: "Conforme 2",
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          },
        },

        Note6: {
          title: "Nota 6",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => this.notesCellRenderer(cell),
          cellClass: 'd-none',
        },
        Absences6: {
          title: "Inasistencias 6",
          filter: false,
          editable: false,
          type: "number",
        },
        remedialGrade6: {
          title: "Recuperación 6",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording6: {
          title: "Conforme 6",
          filter: false,
          editable: false,
          editor: {
            type: 'list',
            config: {
              list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
            }
          },
          type: 'custom',
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(row => {
              console.log("algo se edito")
              // this.saveDocenteDetails(row.id)
            });
          },
        },

        DateModified: {
          title: "Fecha de Modificación",
          filter: false,
          editable: false,
          type: "number"
        },
        FinalRecord: {
          title: "Nota Final",
          filter: false,
          editable: false,
          type: "html",
          valuePrepareFunction: (cell) => {
            if (cell >= 1 && cell <= 2.5) {
              return `<div class="ag-grid__pill ag-grid__pill--red">${cell}</div>`;
            } else if (cell >= 2.5 && cell <= 3.5) {
              return `<div class="ag-grid__pill ag-grid__pill--orange">${cell}</div>`;
            } else {
              return `<div class="">${cell}</div>`;
            }
          }
        },
        TotalAbsences: {
          title: 'Total Inasistencias',
          filter: false,
          editable: false,
          type: "number"
        },
      },

      rowClassFunction: (row) => {
        const classes = [
          row.data.DateModified ? 'child-row' : 'arent-row',
          row.data.isWithdrawn ? 'smart-table__disable-row' : ''
        ]

        return classes.join(' ')
      }
    };


    return Object.assign({}, newTableData)

  }
  callSelectSubjectGroup(model) {
    //reset the values
    this.settingsStudentRecordHistory = this.resetTable();

    this.settingsStudentRecordHistory.columns.Note1.editable = false;
    this.settingsStudentRecordHistory.columns.Note2.editable = false;
    this.settingsStudentRecordHistory.columns.Note3.editable = false;
    this.settingsStudentRecordHistory.columns.Note4.editable = false;
    this.settingsStudentRecordHistory.columns.Note5.editable = false;
    this.settingsStudentRecordHistory.columns.Note6.editable = false;

    this.showdataHistory = false;

    this.date1 = null;
    this.date2 = null;
    this.date3 = null;
    this.date4 = null;
    this.date5 = null;
    this.date6 = null;

    this.date1_html = null;
    this.date2_html = null;
    this.date3_html = null;
    this.date4_html = null;
    this.date5_html = null;
    this.date6_html = null;

    this.dateInfos = []
    this.dateInfos_html = []
    this.counterNota_info = []
    //==============

    let subgroup = this.SubjectsProfesor.find(x => x.id == this.SubjectGID);
    this.numberMidTerms = subgroup.minTerms.length;

    console.log("Cantidad de midterms", this.numberMidTerms)
    console.log("el subgrup es", subgroup)

    for (let i = 1; i <= 6; i++) {
      const item = subgroup.minTerms.find(x => x.MidTermNum == i);
      if (!item) {
        delete this.settingsStudentRecordHistory.columns[`Note${i}`];
        delete this.settingsStudentRecordHistory.columns[`Absences${i}`];
        delete this.settingsStudentRecordHistory.columns[`IsAccording${i}`];
        delete this.settingsStudentRecordHistory.columns[`remedialGrade${i}`];
      }
      else {
        if (this.SHCOOL_NAME === "CEDOC")
          delete this.settingsStudentRecordHistory.columns[`remedialGrade${i}`];


      }
    }


    // if (this.SHCOOL_NAME === "CELIC")
    //   delete this.settingsStudentRecordHistory.columns[`FinalRecord`];

    //obtener los id de cada minterm
    if (this.numberMidTerms > 0) {
      console.log(subgroup.minTerms, 'md terms list');


      range(1, this.numberMidTerms).subscribe({
        next: midTermNum => {
          const midterm = subgroup.minTerms.find(x => x.MidTermNum == midTermNum);

          this.settingsStudentRecordHistory.columns[`Note${midTermNum}`].title = midterm.NameMid;
          this.coursesService.getAllDaterecordByMidterm(midterm.id).pipe(
            take(1),
            catchError((error) => {
              console.error('Error en la suscripción:', error);
              console.error('Línea donde se produjo el error:', error.stack);
              return throwError(error);
            })
          ).subscribe(p => {

            //ORDENO DEL ID MAYOR AL MENOR PARA CONOCER EL ULTIMO REGISTRO DE DATERECORD
            p = p.sort(function (a, b) {
              // SE ORDENA DE FORMA ASCENDENTE
              return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? 1 : -1;
            });
            this.counterNota_info[midTermNum - 1] = p.length;
            console.debug("LA RESPUESTA DE getAllDaterecordByMidterm1");
            console.debug(p);
            this.settingsStudentRecordHistory.columns[`Note${midTermNum}`].title = p[0]["midTerm"]["NameMid"] + " " + p[0]["midTerm"]["Porcent"] + "%"
            this.settingsStudentRecordHistory.columns[`Note${midTermNum}`].editable = this.IsBetwen(p);

            this.settingsStudentRecordHistory.columns[`Absences${midTermNum}`].editable = this.IsBetwen(p);
            if (this.SHCOOL_NAME === "CELIC") this.settingsStudentRecordHistory.columns[`remedialGrade${midTermNum}`].editable = this.IsBetwen(p);

            //TODO: verificar si celic puede modificar notas
            // this.settingsStudentRecordHistory.columns[`Note${midTermNum}`].editable =
            //   this.SHCOOL_NAME === "CELIC" ? false : this.IsBetwen(p);

            // this.settingsStudentRecordHistory.columns[`Absences${midTermNum}`].editable =
            //   this.SHCOOL_NAME === "CELIC" ? false : this.IsBetwen(p);

            // this.date1 = p[0];   
            this.dateInfos[midTermNum - 1] = p[0]
            console.log("this.dateInfos", this.dateInfos);
            this.percentages[`Note${midTermNum}`] = p[0]["midTerm"]["Porcent"];
            this.dateInfos_html[midTermNum - 1] = p
            console.log("dateInfos_html", this.dateInfos_html)
            // this.date1_html = p;
          })






        },
        complete: () => {
          this.getStudents();
          this.loadHistoryGrades();

          console.log(" this.settingsStudentRecordHistory-------", this.settingsStudentRecordHistory)
        }
      });


    } else {
      this.getStudents();
      this.loadHistoryGrades();
    }

  }

  getStudents() {

    /* this.enable() */

    this.coursesService.getAllStudentRecord(this.SubjectGID).pipe(take(1)).subscribe(p => {

      p.sort(function (a, b) {
        // Ascending: first age less than the previous

        return a["LastNames"].localeCompare(b["LastNames"]);
      });


      console.log("LA LISTA DE ESTUDIANTES PARA EL CURSO SELECCIONADO SON");
      console.log(p);
      this.totalStudent = p.length;
      this.listStudents = p
      this.sourceStudentRecord.load(p);

    });

  }

  midtTermSelectedModal(nombre: string, dateObject: any) {
    (this.extendDates.DateStartRecord = new Date(this.serverTime)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "/")),
      (this.extendDates.DateEndRecord = new Date(
        moment(this.serverTime).add(1, "days").toString()
      )
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/"));

    this.enablesaveprocess = false;
    this.enablesave = true;
    this.enableclose = true;
    this.midTermSelected = nombre;
    console.debug(this.extendDates);
    console.debug(dateObject);
    this.extendDates.SubjectGroupID = dateObject.SubjectGroupID;
    this.extendDates.MidTermID = dateObject.MidTermID;
  }

  //On open edit date record modal
  public onEditDateRecord(name: string, data: any) {
    if (!data)
      return

    this.midTermSelected = name;

    const result = {
      ...data,
      DateEndRecord: data.DateEndRecord.split('T')[0],
      DateStartRecord: data.DateStartRecord.split('T')[0],
    }

    this.dateRecordToEdit = result;
  }

  //On update date record
  updateDateRecord(model: any, valid: any) {
    if (!valid)
      return;

    this.authService.swalLoading("Guardando fecha");

    const dataToUpdate = {
      DateStartRecord: this.dateRecordToEdit.DateStartRecord,
      DateEndRecord: this.dateRecordToEdit.DateEndRecord,
      Excuse: this.dateRecordToEdit.Excuse
    }

    this.coursesService.updateDateRecord(dataToUpdate, this.dateRecordToEdit.id).subscribe({
      next: (resp) => {
        Swal({
          type: 'success',
          title: '',
          text: 'Fecha actualizada satisfactoriamente.',
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            jQuery("#ModalEditDate").modal("hide");
            this.callSelectSubjectGroup(this.SubjectSelected);
          }
        })
      }, error: (err) => {
        console.log(err);
        this.authService.swalError('Error!', `Ocurrió un error al actualizar la fecha: ${err.message}`);
      }
    });
  }

  public validateDateRecord(start, end): boolean {
    if (!start.value || !end.value)
      return;

    const startDateValue = new Date(start.value);
    const endDateValue = new Date(end.value);

    return endDateValue > startDateValue;
  }

  ngOnInit() { }

  ngOnDestroy() { }

  onSearch(query: string = "") { }

  onEditConfirm(event): any {
    //#region This is only visual
    const { Note1, Note2, Note3, Note4, Note5, Note6 } = event.newData;
    const { Absences1, Absences2, Absences3, Absences4, Absences5, Absences6 } =
      event.newData;
    const { remedialGrade1, remedialGrade2, remedialGrade3, remedialGrade4, remedialGrade5, remedialGrade6 } =
      event.newData;
    event.newData.FinalRecord = this.calculateFinalRecord([
      Number(remedialGrade1 ? remedialGrade1 : Note1 || 0),
      Number(remedialGrade2 ? remedialGrade3 : Note2 || 0),
      Number(remedialGrade3 ? remedialGrade3 : Note3 || 0),
      Number(remedialGrade4 ? remedialGrade4 : Note4 || 0),
      Number(remedialGrade5 ? remedialGrade5 : Note5 || 0),
      Number(remedialGrade6 ? remedialGrade6 : Note6 || 0),
    ]);

    event.newData.TotalAbsences = this.calcularTotalAbsences([
      Number(Absences1 || 0),
      Number(Absences2 || 0),
      Number(Absences3 || 0),
      Number(Absences4 || 0),
      Number(Absences5 || 0),
      Number(Absences6 || 0),
    ]);
    //#endregion

    const invalidInput = this.validateInput(event.newData).error;

    if (invalidInput)
      return this.authService.swalError(
        "Error",
        this.validateInput(event.newData).message
      );

    const { TotalAbsences } = event.newData;
    const totalAbsences = parseInt(TotalAbsences);

    this.authService.swalLoading('Guardando...');
    this.coursesService.updateUserSubject(event.data.id, totalAbsences)
      .pipe(
        switchMap((updatedUserSubject: any) => {
          const observables: Observable<any>[] = [];
          const userRecords = updatedUserSubject.userRecord;

          const minTerms = userRecords.map( m => m.midTerm);
          for (let i = 0; i < minTerms.length; i++) {
            const minTerm = minTerms[i];
            const noteId = userRecords.find(e => e.midTerm.id === minTerm.id);

            if (noteId) {
              const mtNum = minTerm.MidTermNum;
              const noteKey = `Note${mtNum}`;
              const absenceKey = `Absences${mtNum}`;
              const remedialGradeKey = `remedialGrade${mtNum}`;

              const data = {
                ParcialRecord: parseFloat(event.newData[noteKey]),
                absences: parseFloat(event.newData[absenceKey]),
                ...(this.SHCOOL_NAME === 'CELIC' ? { remedialGrade: event.newData[remedialGradeKey] == "" ? null : parseFloat(event.newData[remedialGradeKey]) } : {})
              }
              const obs = this.saveUserRecord(noteId.id, data, event.data.id);
              observables.push(obs);
            }
          }
          return observables.length === 0 ? of([]) : forkJoin(observables);
        })
      ).subscribe({
        next: () => {
          this.loadHistoryGrades()
          event.confirm.resolve(event.newData);
        }, error: (err) => {
          console.log(err);
        }
      })
  }


  /**
   * Get final record
   * ...
   * @returns
   */
  private calculateFinalRecord(notes: number[]): string {
    const percentages = [
      this.percentages.Note1 || 0,
      this.percentages.Note2 || 0,
      this.percentages.Note3 || 0,
      this.percentages.Note4 || 0,
      this.percentages.Note5 || 0,
      this.percentages.Note6 || 0,
    ];

    const totalPercentage = percentages.reduce((sum, percent) => sum + percent, 0);
    const weightedSum = notes.reduce((sum, note, index) => sum + note * percentages[index], 0);
    return (weightedSum / totalPercentage).toFixed(2);
  }

  /**
   * Get Total Absences
   * @param absences
   * @returns
   */
  private calcularTotalAbsences(absences: number[]): number {
    return absences.reduce((total, absence) => total + absence, 0);
  }

  /**
  * Validates the note inputs value
  *
  * @param newData
  * @returns
  */
  private validateInput(newData) {

    const getMaxNoteValue = () => {

      if (this.SubjectSelected.TypeRecord == "Cualitativo") {
        if (this.qualitativeDescription.length === 0)
          return { minValue: 1, maxValue: 10 };
        let minValue = Infinity;
        let maxValue = -Infinity;

        this.qualitativeDescription.forEach((obj) => {
          const [min, max] = obj.range.split("-").map(Number);
          if (min < minValue) minValue = min;
          if (max > maxValue) maxValue = max;
        });

        return { minValue, maxValue };
      }
      else {
        let ranges = this.SubjectSelected.TypeRecord.split("-").map(x => Number(x))
        return { minValue: ranges[0], maxValue: ranges[1] };
      }

    }

    const { minValue, maxValue } = getMaxNoteValue();

    const isNoteValid = (note: number) => {
      const validFormat = /^(\d+(\.\d+)?)$/.test(note.toString());
      return validFormat && note >= minValue && note <= maxValue;
    };

    const isAbsencensValid = (key: string) => {
      const absencesValue = parseInt(newData[key], 10);
      const validFormat = /^(\d+(\.\d+)?)$/.test(newData[key]);
      return (
        this.settingsStudentRecordHistory.columns[key] &&
        this.settingsStudentRecordHistory.columns[key].editable &&
        (!validFormat || absencesValue < 0)
      );
    };

    const isRemedialGradeInvalid = (key: string) => {
      const remedialGradeValidValue = parseInt(newData[key], 10);
      const validFormat = /^(\d+(\.\d+)?)$/.test(newData[key]);
      return (
        this.settingsStudentRecordHistory.columns[key] && this.settingsStudentRecordHistory.columns[key].editable &&
        (!validFormat || remedialGradeValidValue < 0)
      );
    };

    for (let i = 1; i <= 6; i++) {
      const noteKey = `Note${i}`;
      const absenceKey = `Absences${i}`;
      const remedialGrade = `remedialGrade${i}`;

      if (newData[absenceKey])
        if (isAbsencensValid(absenceKey))
          return {
            error: true,
            message: `La cantidad de inasistencias debe ser un número positivo válido.`,
          };

      if (newData[remedialGrade])
        if (isRemedialGradeInvalid(remedialGrade) && this.SHCOOL_NAME === 'CELIC')
          return {
            error: true,
            message: `La Recuperación debe ser un número positivo válido.`,
          };

      //If the percentage is 0, do nothing
      if (this.percentages[`Note${i}`] > 0) {
        const noteEditable =
          this.settingsStudentRecordHistory.columns[noteKey] &&
          this.settingsStudentRecordHistory.columns[noteKey].editable;
        const noteValue = newData[noteKey];

        if (noteEditable && !isNoteValid(noteValue)) {
          return {
            error: true,
            message: `La nota debe estar entre ${minValue}.0 y ${maxValue}.0`,
          };
        }
      }

      return { error: false, message: "Datos válidos" };
    }
  }


  /**
   * Query to store records and midTerms
   *
   * @param noteId
   * @param parcialRecord
   * @returns
   */
  private saveUserRecord(noteId: number | undefined, data: any, userSubjectId: number) {
    return this.coursesService.saveUserRecord(noteId, { IsModify: true, ...data, UserSubjectID: userSubjectId })
      .pipe(
        switchMap((userRecord) => {
          return this.coursesService.patchMidTermsByID(userRecord["MidTermID"], { IsDisabled: true }).pipe(
            map(midTerm => ({ midTerm, userRecord }))
          )
        }
        )
      )
  }


  /*   enable() {
      if ( this.IsPersonal ) {
        this.settingsStudentRecordHistory.columns.Note1.editable = true;
        this.settingsStudentRecordHistory.columns.Note2.editable = true;
        this.settingsStudentRecordHistory.columns.Note3.editable = true;
      }
    } */

  /*   disableDate() {
      this.IsPersonal = false
    }
   */

  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;

    }
    return classes;
  }

  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num) return num.NameTSchool;
    return "Nombre de Escuela";
  }

  ValidateDate(modelstart, modelend) {
    var response = false;
    if (this.enablesaveprocess == false) this.enablesave = true;
    else this.enablesave = false;

    var ActualDate = new Date(this.serverTime);
    var InputDateSince = new Date(modelstart); //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
    var InputDateUntil = new Date(modelend); //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE

    //console.log(InputDateUntil.toISOString())
    //cuando indica que trabaja actualmente

    if (InputDateSince >= InputDateUntil) {
      response = true;

      this.enablesave = false;
    }

    return response;
  }

  createExtendDate(model, valid) {
    console.log(model);

    if (valid) {

      this.authService.swalLoading("Creando Nueva Fecha Extraordinaria");

      this.enablesave = false;
      this.enablesaveprocess = true;
      this.enableclose = false;
      this.extendDates.ProfileWhAutorizeID = parseInt(this.user);
      this.extendDates.IsExtension = true
      this.attachmentService.NewDatesSuports(this.fileToUpload).then((result) => {
        this.extendDates.attachmentLink = `/attachments/${environment.BUCKET_EXTENDED_DATES}/download/` + result['name'];
        this.extendDates.Excuse = model.Excuse;
        console.debug(this.extendDates);
        /*
        this.sourceStudentRecord.getAll().then(respuesta => {
        
            for (var index = 0; index < respuesta.length; index++) {
              console.debug(respuesta[index])
             
              var saveUserRecordObject={
                UserSubjectID:respuesta[index].id,
                ParcialRecord:respuesta[index]["Note"+this.midTermSelected],
                //ParcialRecord:700
                DateRecordID:999999,
                MidTermID:model.MidTermID,
                IsAccording:1,
                IsModify:1
                
  
  
              }
              console.debug("SE LE VA A GUARDAR")
              console.debug(saveUserRecordObject)
              
            }
        
            });
  
  
          */

        //creo el nuevo datercord
        this.coursesService
          .saveDateRecord(this.extendDates)
          .pipe(take(1)).subscribe(responseDateRecord => {
            console.debug("LA RESPUESTA DE RESPONDE DATERECORD ES");
            console.debug(responseDateRecord.id);
            //obtengo el id del daterecord para guardar los userrecord
            this.sourceStudentRecord.getAll().then(respuesta => {
              this.DatosARegistrar = respuesta.length;
              this.ContadorWhile = 0;
              this.ContadorWhileAnterior = -1;

              if (respuesta.length === 0) {
                jQuery("#ModalExtenDate").modal("hide");
                Swal({
                  type: 'success',
                  title: '',
                  text: 'Fecha Extraordinaria Creada',
                  showConfirmButton: true,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.value) {
                    this.callSelectSubjectGroup(this.SubjectSelected);
                  }
                })

                return;
              }




              for (var index = 0; index < respuesta.length; index++) {
                //console.debug(respuesta[index])

                var saveUserRecordObject = {
                  UserSubjectID: respuesta[index].id,
                  ParcialRecord: respuesta[index]["Note" + this.midTermSelected],
                  //ParcialRecord:700,
                  DateRecordID: responseDateRecord.id,
                  MidTermID: model.MidTermID,
                  IsAccording: true,
                  IsModify: true
                };

                this.coursesService
                  .saveUserRecord(0, saveUserRecordObject)
                  .pipe(take(1)).subscribe(ResponseSavedUserRecord => {
                    console.log(this.ContadorWhile);
                    //SI TODOS LOS USUARIOS HAN SIDO GUARDADOS, MOSTRAR NUEVA ALERTA, CERRRAR VENTANA MODAL Y CARGAR NUEVAMENTE LA MATERIA
                    if (this.ContadorWhile + 1 >= this.DatosARegistrar) {
                      this.authService.swalSuccess(
                        "Fecha Extraordinaria Creada",
                        ""
                      );
                      jQuery("#ModalExtenDate").modal("hide");
                      this.callSelectSubjectGroup(this.SubjectSelected);
                    } else this.ContadorWhile = this.ContadorWhile + 1;
                  });
                // console.debug("SE LE VA A GUARDAR")
                //console.debug(saveUserRecordObject)
              }
            });


          });


      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })
    }


  }




  ExportList() {
    var Exporar = [];
    Exporar.push({
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Note:1": this.settingsStudentRecordHistory.columns.Note1.title.toUpperCase(),
      "Note:2": this.settingsStudentRecordHistory.columns.Note2.title.toUpperCase(),
      "Note:3": this.settingsStudentRecordHistory.columns.Note3.title.toUpperCase(),

      /*       "Note:1": this.settingsStudentRecord.columns.Note1.title.toUpperCase(),
            "Note:2": this.settingsStudentRecord.columns.Note2.title.toUpperCase(),
            "Note:3": this.settingsStudentRecord.columns.Note3.title.toUpperCase(), */
      "FinalRecord": "NOTA FINAL",
    })
    this.listStudents.forEach(element => {
      Exporar.push({
        "LastNames": element.LastNames.toUpperCase(),
        "Names": element.Names.toUpperCase(),
        "Note:1": isNullOrUndefined(element.Note1) ? "--" : element.Note1,
        "Note:2": isNullOrUndefined(element.Note2) ? "--" : element.Note2,
        "Note:3": isNullOrUndefined(element.Note3) ? "--" : element.Note3,
        "FinalRecord": element.FinalRecord
      })
    });
    new AngularCsv(Exporar, 'Lista Estudiantes');
  }


  loadHistoryGrades() {
    let finalTable = []

    this.authService.swalLoading('Cargando');

    this.coursesService.getAllStudentRecordRrecord(this.SubjectGID).pipe(take(1)).subscribe(students => {
      let tempTable = []
      console.log('estudiantes', students)

      students.forEach(student => {
        let note1Id;
        let note1;
        let absences1;
        let isAccording1;
        let remedialGrade1;
        let rUserRecord1;

        let note2Id;
        let note2;
        let absences2;
        let isAccording2;
        let remedialGrade2;
        let rUserRecord2;

        let note3Id;
        let note3;
        let absences3;
        let isAccording3;
        let remedialGrade3;
        let rUserRecord3;


        let note4Id;
        let note4;
        let absences4;
        let isAccording4;
        let remedialGrade4;
        let rUserRecord4;

        let note5Id;
        let note5;
        let absences5;
        let isAccording5;
        let remedialGrade5;
        let rUserRecord5;

        let note6Id;
        let note6;
        let absences6;
        let isAccording6;
        let remedialGrade6;
        let rUserRecord6;

        // Crear Lista  por Defecto (sin historial de notas)
        var num1 = student.RecordMidTerm.find(x => x.MidTermNum == "1");
        if (num1) {
          note1Id = num1.id;
          note1 = num1.ParcialRecord;
          absences1 = num1.absences
          isAccording1 = num1.IsAccording;
          remedialGrade1 = num1.remedialGrade;
          if (num1.rUserRecord)
            rUserRecord1 = num1.rUserRecord
        }
        var num2 = student.RecordMidTerm.find(x => x.MidTermNum == "2");
        if (num2) {
          note2Id = num2.id;
          note2 = num2.ParcialRecord;
          absences2 = num2.absences
          isAccording2 = num2.IsAccording;
          remedialGrade2 = num2.remedialGrade;
          if (num2.rUserRecord)
            rUserRecord2 = num2.rUserRecord
        }
        var num3 = student.RecordMidTerm.find(x => x.MidTermNum == "3");
        if (num3) {
          note3Id = num3.id;
          note3 = num3.ParcialRecord;
          absences3 = num3.absences
          isAccording3 = num3.IsAccording;
          remedialGrade3 = num3.remedialGrade;
          if (num3.rUserRecord)
            rUserRecord3 = num3.rUserRecord
        }
        var num4 = student.RecordMidTerm.find(x => x.MidTermNum == "4");
        if (num4) {
          note4Id = num4.id;
          note4 = num4.ParcialRecord;
          absences4 = num4.absences
          isAccording4 = num4.IsAccording;
          remedialGrade4 = num4.remedialGrade;
          if (num4.rUserRecord)
            rUserRecord4 = num4.rUserRecord
        }
        var num5 = student.RecordMidTerm.find(x => x.MidTermNum == "5");
        if (num5) {
          note5Id = num5.id;
          note5 = num5.ParcialRecord;
          absences5 = num5.absences
          isAccording5 = num5.IsAccording;
          remedialGrade5 = num5.remedialGrade;
          if (num5.rUserRecord)
            rUserRecord5 = num5.rUserRecord
        }
        var num6 = student.RecordMidTerm.find(x => x.MidTermNum == "6");
        if (num6) {
          note6Id = num6.id;
          note6 = num6.ParcialRecord;
          absences6 = num6.absences
          isAccording6 = num6.IsAccording;
          remedialGrade6 = num6.remedialGrade;
          if (num6.rUserRecord)
            rUserRecord6 = num6.rUserRecord
        }
        let course = {
          id: student.id,
          Names: student.Names,
          TotalAbsences: student.Absences || 0,
          LastNames: student.LastNames,
          Document: student.Document,
          CedulaMil: student.CedulaMil,
          FinalRecord: student.FinalRecord,
          SubjectGroupID: student.SubjectGroupID,
          Note1Id: note1Id,
          Note1: note1,
          Absences1: absences1,
          IsAccording1: isAccording1,
          remedialGrade1: remedialGrade1,
          Note2Id: note2Id,
          Note2: note2,
          Absences2: absences2,
          IsAccording2: isAccording2,
          remedialGrade2: remedialGrade2,
          Note3Id: note3Id,
          Note3: note3,
          Absences3: absences3,
          IsAccording3: isAccording3,
          remedialGrade3: remedialGrade3,
          Note4Id: note4Id,
          Note4: note4,
          Absences4: absences4,
          IsAccording4: isAccording4,
          remedialGrade4: remedialGrade4,
          Note5Id: note5Id,
          Note5: note5,
          Absences5: absences5,
          IsAccording5: isAccording5,
          remedialGrade5: remedialGrade5,
          Note6Id: note6Id,
          Note6: note6,
          Absences6: absences6,
          IsAccording6: isAccording6,
          remedialGrade6: remedialGrade6,
          rUserRecord1: rUserRecord1,
          rUserRecord2: rUserRecord2,
          rUserRecord3: rUserRecord3,
          rUserRecord4: rUserRecord4,
          rUserRecord5: rUserRecord5,
          rUserRecord6: rUserRecord6,
          isWithdrawn: student.isWithdrawn,

        }
        tempTable.push(course)
      });


      console.log('tempTable', tempTable)



      // Crear Tabla final a partir de los rUserRecord
      tempTable.forEach(element => {
        let student = {
          id: element.id,
          TotalAbsences: element.TotalAbsences || 0,
          Absences1: element.Absences1 || 0,
          Absences2: element.Absences2 || 0,
          Absences3: element.Absences3 || 0,
          Absences4: element.Absences4 || 0,
          Absences5: element.Absences5 || 0,
          Absences6: element.Absences6 || 0,
          Names: element.Names,
          LastNames: element.LastNames,
          Document: element.Document,
          CedulaMil: element.CedulaMil,
          FinalRecord: element.FinalRecord.toFixed(2),
          SubjectGroupID: element.SubjectGroupID,
          Note1Id: element.Note1Id,
          Note1: element.Note1,
          IsAccording1: element.IsAccording1,
          remedialGrade1: element.remedialGrade1,
          Note2Id: element.Note2Id,
          Note2: element.Note2,
          IsAccording2: element.IsAccording2,
          remedialGrade2: element.remedialGrade2,
          Note3Id: element.Note3Id,
          Note3: element.Note3,
          IsAccording3: element.IsAccording3,
          remedialGrade3: element.remedialGrade3,
          Note4Id: element.Note4Id,
          Note4: element.Note4,
          IsAccording4: element.IsAccording4,
          remedialGrade4: element.remedialGrade4,
          Note5Id: element.Note5Id,
          Note5: element.Note5,
          IsAccording5: element.IsAccording5,
          remedialGrade5: element.remedialGrade5,
          Note6Id: element.Note6Id,
          Note6: element.Note6,
          IsAccording6: element.IsAccording6,
          remedialGrade6: element.remedialGrade6,
          isWithdrawn: element.isWithdrawn,
        }

        finalTable.push(student)



        if (element.rUserRecord1 && element.rUserRecord1.length > 0) {
          element.rUserRecord1.forEach((elementRecord, index) => {
            let newnote1Id;
            let newnote1;
            let newabsences1;
            let newisAccording1;
            let newremedialGrade1;

            let newnote2Id;
            let newnote2;
            let newabsences2;
            let newisAccording2;
            let newremedialGrade2;


            let newnote3Id;
            let newnote3;
            let newabsences3;
            let newisAccording3;
            let newremedialGrade3;


            let newnote4Id;
            let newnote4;
            let newabsences4;
            let newisAccording4;
            let newremedialGrade4;

            let newnote5Id;
            let newnote5;
            let newabsences5;
            let newisAccording5;
            let newremedialGrade5;

            let newnote6Id;
            let newnote6;
            let newabsences6;
            let newisAccording6;
            let newremedialGrade6;

            let newdateModified1;


            if (element.rUserRecord1) {
              if (element.rUserRecord1[index]) {
                let newElement1 = JSON.parse(element.rUserRecord1[index].NewValue)
                newnote1Id = newElement1.DateRecordID
                newnote1 = newElement1.ParcialRecord
                newabsences1 = newElement1.absences
                newisAccording1 = newElement1.IsAccording
                newremedialGrade1 = newElement1.remedialGrade
                newdateModified1 = moment(element.rUserRecord1[index].TimeStamp).format("YYYY-MM-DD HH:mm:ss") + " Modificada por:  " + element.rUserRecord1[index].userapp.Name1 + " " + element.rUserRecord1[index].userapp.LastName1
              }
            }
            if (element.rUserRecord2) {
              if (element.rUserRecord2[index]) {
                let newElement2 = JSON.parse(element.rUserRecord2[index].NewValue)
                newnote2Id = newElement2.DateRecordID
                newnote2 = newElement2.ParcialRecord
                newabsences2 = newElement2.absences
                newisAccording2 = newElement2.IsAccording
                newremedialGrade2 = newElement2.remedialGrade
              }
            }

            if (element.rUserRecord3) {
              if (element.rUserRecord3[index]) {
                let newElement3 = JSON.parse(element.rUserRecord3[index].NewValue)
                newnote3Id = newElement3.DateRecordID
                newnote3 = newElement3.ParcialRecord
                newabsences3 = newElement3.absences
                newisAccording3 = newElement3.IsAccording
                newremedialGrade3 = newElement3.remedialGrade
              }
            }


            if (element.rUserRecord4) {
              if (element.rUserRecord4[index]) {
                let newElement4 = JSON.parse(element.rUserRecord4[index].NewValue)
                newnote4Id = newElement4.DateRecordID
                newnote4 = newElement4.ParcialRecord
                newabsences4 = newElement4.absences
                newisAccording4 = newElement4.IsAccording
                newremedialGrade4 = newElement4.remedialGrade
              }
            }
            if (element.rUserRecord5) {
              if (element.rUserRecord5[index]) {
                let newElement5 = JSON.parse(element.rUserRecord5[index].NewValue)
                newnote5Id = newElement5.DateRecordID
                newnote5 = newElement5.ParcialRecord
                newabsences5 = newElement5.absences
                newisAccording5 = newElement5.IsAccording
                newremedialGrade5 = newElement5.remedialGrade
              }
            }
            if (element.rUserRecord6) {
              if (element.rUserRecord6[index]) {
                let newElement6 = JSON.parse(element.rUserRecord6[index].NewValue)
                newnote6Id = newElement6.DateRecordID
                newnote6 = newElement6.ParcialRecord
                newabsences6 = newElement6.absences
                newisAccording6 = newElement6.IsAccording
                newremedialGrade6 = newElement6.remedialGrade
              }
            }

            const newFinalRecord = this.calculateFinalRecord([
              Number(newnote1 || 0),
              Number(newnote2 || 0),
              Number(newnote3 || 0),
              Number(newnote4 || 0),
              Number(newnote5 || 0),
              Number(newnote6 || 0),
            ]);

            const newTotalAbsences = this.calcularTotalAbsences([
              Number(newabsences1 || 0),
              Number(newabsences2 || 0),
              Number(newabsences3 || 0),
              Number(newabsences4 || 0),
              Number(newabsences5 || 0),
              Number(newabsences6 || 0)

            ]);

            let newStudent = {
              id: element.id,
              TotalAbsences: newTotalAbsences,
              Absences1: newabsences1 || 0,
              Absences2: newabsences2 || 0,
              Absences3: newabsences3 || 0,
              Absences4: newabsences4 || 0,
              Absences5: newabsences5 || 0,
              Absences6: newabsences6 || 0,
              Names: '-',
              LastNames: '-',
              Document: element.Document,
              CedulaMil: element.CedulaMil,
              FinalRecord: newFinalRecord,
              SubjectGroupID: element.SubjectGroupID,
              Note1Id: newnote1Id,
              Note1: newnote1,
              IsAccording1: newisAccording1,
              remedialGrade1: newremedialGrade1,
              Note2Id: newnote2Id,
              Note2: newnote2,
              IsAccording2: newisAccording2,
              remedialGrade2: newremedialGrade2,
              Note3Id: newnote3Id,
              Note3: newnote3,
              IsAccording3: newisAccording3,
              remedialGrade3: newremedialGrade3,
              Note4Id: newnote4Id,
              Note4: newnote4,
              IsAccording4: newisAccording4,
              remedialGrade4: newremedialGrade4,
              Note5Id: newnote5Id,
              Note5: newnote5,
              IsAccording5: newisAccording5,
              remedialGrade5: newremedialGrade5,
              Note6Id: newnote6Id,
              Note6: newnote6,
              IsAccording6: newisAccording6,
              remedialGrade6: newremedialGrade6,
              DateModified: newdateModified1,
              isWithdrawn: element.isWithdrawn
            }

            finalTable.push(newStudent)
          });
        }


      })

      console.log(finalTable, 'fina ltable');

      this.sourceStudentRecordHistory.load(finalTable)
      this.showdataHistory = true;

      this.authService.swalEndLoad();
    })
  }

  public onPrintModal() {
    this.sourceStudentRecordHistory.getFilteredAndSorted().then((elements) => {
      this.usersToPrint = elements;
      console.log(elements);
    });
  }

  public downloadPdf() {
    window.print();
  }

  isHiddenColumn(column: string) {
    console.log(column);

    return this._hiddenColumns.includes(column);
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.UrlAPI}/${urlComplement}`;
    return url;
  }
}
